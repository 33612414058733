<template>
  <div class="background-color">
    <el-scrollbar style="height:100%">
      <div class="wrap1">
        <div class="update-wrap d-flex flex-column ai-center">
          <div class="background-img d-flex jc-center">
            <img src="../../../images/designbackground.jpg" alt="">
          </div>
          <div class="update-button fs-18 m-c-p d-flex jc-center ai-center" @click="publishDesign">
            <strong>上传作品</strong>
          </div>
        </div>
        <div class="content">
          <div class="d-flex flex-wrap">
            <design-card  v-for="item in designList" :key="item._id" :designContent="item"></design-card>
          </div>
        </div>
      </div>
      <div class="mt-30">
        <page-com :total="currentDesignCount" :singleCount= "40" @changePage="changePage"></page-com>
      </div>
      <div>
        <footer-com :color="true"></footer-com>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import DesignCard from '../components/designCard.vue';
import FooterCom from '../components/Footer';
import PageCom from '../components/PageCom';
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
export default {
  data(){
    return {
      designList: [],
      currentDesignCount: 0
    }
  },
  methods:{
    async getDesignList(page){
      const model = await this.$axios.get(`/rest/designList/${page - 1}`)
      model.data.map(item => {
        let count = 0
        for(let i = 0; i < (item.comment ? item.comment.length : 0); i++){
          count ++
          for(let j = 0; j < (item.comment[i].comment ? item.comment[i].comment.length : 0); j ++){
            count ++
          }
        }
        item.commentsCount = count
      })
      this.currentDesignCount = model.data.count
      this.designList = model.data
    },
    publishDesign(){
      this.$store.state.user._id && this.$router.push('/publishDesign')
      this.$store.state.user._id || this.$message({message: '请先登录！'})
    },
    changePage(page){
      this.toTop()
      this.getDesignList(page)
    },
    toTop() {
      // el-scrollbar 容器
      const el = this.$refs['scroll'].wrap;
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
  },
  mounted(){
    this.getDesignList(1)
  },
  components:{
      DesignCard,
      FooterCom,
      PageCom
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
  .background-color{
    background: map-get($colors, 'background' );
    height: 103vh;
    .wrap1{
      min-height: calc(100vh - 23rem);
      .update-wrap{
        .background-img{
          width: 100vw;
          height: 30rem;
        }
        .update-button{
          width: 20.8rem;
          height: 4.8rem;
          text-align: center;
          line-height: 4rem;
          border-radius: 0.5rem;
          transform: translate(-4.5vw, -2.5rem);
          background: map-get($colors, 'background');
          strong{
            display: block;
            background: map-get($colors, 'purple' );
            color: white;
            width: 20rem;
            height: 4rem;
            border-radius: 0.5rem;
          }
        }
      }
      .content{
          width: 140rem;
          margin: 0 auto;
          margin-top: 5rem;
          transform: translate(-4.5vw, 0);
        }
    }
  }
</style>