<template>
  <div class="com-wrap d-flex jc-center">
      <div class="d-flex page-wrap fs-15">
          <div @click="toPage(1)" v-if="currentPage > 3">首页</div>
          <div @click="toPage(currentPage - 1)" v-if="currentPage > 1">上一页</div>
          <div @click="toPage(currentPage - 1)" class="page-box" v-if="currentPage != 1">{{ currentPage - 1 }}</div>
          <div @click="toPage(currentPage)" class="page-box active">{{ currentPage }}</div>
          <div @click="toPage(currentPage + 1)" v-if="currentPage + 1 <= pageCount" class="page-box">{{ currentPage + 1 }}</div>
          <div @click="toPage(currentPage + 2)" v-if="currentPage + 2 <= pageCount" class="page-box">{{ currentPage + 2 }}</div> 
          <div @click="toPage(currentPage + 3)" v-if="currentPage + 3 <= pageCount" class="page-box">{{ currentPage + 3 }}</div> 
          <div v-if="currentPage + 3 < pageCount - 1" class="page-box" style="border:none">...</div>
          <div @click="toPage(currentPage + 1)" v-if="currentPage < pageCount">下一页</div>
          <div @click="toPage(pageCount)" v-if="currentPage != pageCount && pageCount > 6">尾页</div>
      </div>
  </div>
</template>

<script>
export default {
    props:['total', 'singleCount', 'searchId'],
    data(){
        return {
            currentPage: 1
        }
    },
    computed:{
        pageCount(){
            return Math.ceil(this.total/this.singleCount)
        }
    },
    methods:{
        toPage(index){
            if(index != this.currentPage){
                this.currentPage = index
                if(this.searchId){
                    this.$emit('changeSearch', index)
                }else{
                    this.$emit('changePage', index)
                }
            }
        }
    },
    watch:{
        total(value){
            this.currentPage = 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
.com-wrap{
    .page-wrap{
        div{
            cursor: pointer;
            padding: 0.8rem 1.2rem;
            border: 1px solid map-get($colors, 'dark-1');
            border-radius: 0.5rem;
            margin-right: 2rem;
            &.page-box{
                width: 3.8rem;
                height: 3.8rem;
                text-align: center;
            }
            &.active{
                background: map-get($colors, 'purple');
                color: white;
                border-color: map-get($colors, 'purple');
            }
        }
    }
}
</style>