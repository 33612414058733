<template>
  <div class="wrap mb-20 m-c-p" :class="{ card_width: width}"  @click="goDesignInfo">
    <div>
      <img :src="designContent.cover" alt="">
    </div>
    <div class="px-10 fs-15 mb-10">
      <p class="my-5 text-ellipsis">{{designContent.title}}</p>
      <p class="my-5 fs-10 update-time">时间：{{ updateT }}</p>
    </div>
    <div class="d-flex jc-between ai-center mx-10 fs-12 author-wrap">
      <p class="my-10 px-3">{{ designContent.userId.username }} </p>
      <div class="d-flex ai-center" style="color: #999">
        <div class="d-flex ai-center pr-10" style="border-right:1px solid #ccc" title="浏览次数">
          <span class="px-5 iconfont icon-liulan" style="font-size: 1.8rem"></span>
          <span style="font-size: 1.2rem; margin-top: -0.2rem">{{ designContent.views ? designContent.views : 0}}</span>
        </div>
        <div class="d-flex ai-center pr-10" style="border-right:1px solid #ccc" title="评论数量">
          <span class="px-5 iconfont icon-pinglun" style="font-size: 1.6rem; margin-top: 0.1rem"></span>
          <span style="font-size: 1.2rem; margin-top: -0.2rem">{{ designContent.commentsCount ? designContent.commentsCount : 0}}</span>
        </div>
        <div class="d-flex ai-center pr-10" title="点赞数量">
          <span class="px-5 iconfont icon-dianzan" style="font-size: 1.6rem;margin-top: -0.2rem"></span>
          <span style="font-size: 1.2rem; margin-top: -0.2rem">{{ designContent.like ? designContent.like.length : 0}}</span>
        </div>
        <span v-if="edit" class="edit-button m-c-p iconfont icon-bianji mr-10" title="编辑" @click.stop="editDesign"></span>
        <span v-if="edit" class="edit-button m-c-p iconfont icon-shanchu" title="删除" @click.stop="removeDesign"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['designContent', 'width', 'edit'],
  inject:['reload'],
  data(){
    return {

    }
  },
  methods:{
    utc2beijing(utc_datetime) {
      // 转为正常的时间格式 年-月-日 时:分:秒
      if(!utc_datetime){return}
      var T_pos = utc_datetime.indexOf('T');
      var Z_pos = utc_datetime.indexOf('Z');
      var year_month_day = utc_datetime.substr(0,T_pos);
      var hour_minute_second = utc_datetime.substr(T_pos+1,Z_pos-T_pos-1);
      var new_datetime = year_month_day+" "+hour_minute_second; // 2017-03-31 08:02:06

      // 处理成为时间戳
      timestamp = new Date(Date.parse(new_datetime));
      timestamp = timestamp.getTime();
      timestamp = timestamp/1000;

      // 增加8个小时，北京时间比utc时间多八个时区
      var timestamp = timestamp+8*60*60;

      // 时间戳转为时间
      var beijing_datetime = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
      return beijing_datetime; // 2017-03-31 16:02:06
      },
    goDesignInfo(){
      this.$router.push(`/designInfo/${this.designContent._id}`)
    },
    editDesign(){
      this.$router.push(`/publishDesign/${this.designContent._id}`)
    },
    removeDesign(){
      this.$confirm('此操作将永久删除该文件(包括评论、点赞), 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.delete(`/deleteDesign/${this.designContent._id}`).then(result => {
            if(result.data.message == 'success'){
              this.reload()
              this.$message({type: 'success', message: '删除成功！'})
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        })
    }
  },
  computed:{
    updateT(){
      if(this.designContent){
        return this.utc2beijing(this.designContent.created)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
  .wrap{
    width: 26rem;
    height: 30rem;
    border-radius: 0.5rem;
    background: white;
    overflow: hidden;
    margin-right: 2rem;
    &.card_width{
      width: 25rem;
      background: #fbfbfb;
      border:1px solid #eee;
      margin: 0 1rem 2rem 1rem;
    }
    .update-time{
      color: map-get($colors, 'dark-1');
    }
    .author-wrap{
      border-top: 1px solid map-get($colors, 'border-light' );
      .edit-button:hover{
        color: map-get($colors, 'purple');
      }
    }
  }
  .wrap:hover{
    box-shadow: 0 0 1rem rgba(0,0,0,0.12);
  }
</style>